/**
 * Created by dilyand on 2018-03-16.
 */

var sc_applepay = (function () {
	var CONSTANTS = {
		APPLE_MAX_VERSION: 14,
		TYPE_RESPONSE : "RESPONSE",
		TYPE_REQUEST  : "REQUEST"
	};
	var origins = {};
	var rListById = {};
	var paymentsList = [];
	var seq = generateSEQ();
	var _applePay = {
		paymentAuthorizationStarted : false,
		paymentCanceled             : false,
		session                     : false
	};
	var paymentInProgress = false;
	var maxDelayTime = 30000;
	var req_actions = {
		"sync_ack"    : {timeout : 1500},
		"vm"          : {timeout : 30000, callback : onValidateMerchantResponse},
		"paut"        : {timeout : 180000, callback : completeApplePayTrx},
		"completeTrx" : {timeout : 3000}
	};
	var maxSupportedVersion = 1;
	var _Dev = {
		log: function (msg) {
			if (msg && (document.location.hostname === 'localhost' || document.location.origin === 'https://devmobile.sccdev-qa.com')) {
				var o = document.getElementById('output_1');
				if (!o) {
					var b = document.getElementsByTagName('body');
					o = document.createElement('div');
					o.id = 'output_1';
					o.innerText = "From proxy script to the iframe:";
					b[0].appendChild(o);
				}
				var k = document.createElement('div');
				k.className = 'row';
				k.innerText = msg.indexOf('{"applePayJson"') === -1 ? msg : '{"applePayJson": "some_JSON_data"}';
				o.appendChild(k);
			}
		}
	};
	if (window.ApplePaySession) {
		var v = CONSTANTS.APPLE_MAX_VERSION;
		try {
			while (v) {
				if (ApplePaySession.supportsVersion(v)) {
					maxSupportedVersion = v;
					v = false;
				} else {
					v--;
				}
			}
		} catch (e) {
			maxSupportedVersion = 1;
		}
	}

	function findSourceFrame(e) {
		var sourceFrame = null;
		var myFrames = document.getElementsByTagName("IFRAME");
		var eventSource = e.source;
		var eventOrigin = e.origin;
		// same-origin
		for (var i = 0; i < myFrames.length; i++) {
			var f = myFrames[i];
			if (f.contentWindow == eventSource) {
				sourceFrame = f;
				break;
			}
		}
		// cross-origin
		if (sourceFrame == null) {
			for (var i = 0; i < myFrames.length; i++) {
				if (myFrames[i].src.indexOf(eventOrigin) === 0) {
					sourceFrame = myFrames[i];
					break;
				}
			}
		}
		return sourceFrame;
	}

	function generateSEQ() {
		return Math.round(Math.random() * 10000);
	}

	function getFormattedResponse(e) {
		var res = e.data;
		try {
			res = JSON.parse(e.data);
		} catch (e) {
		}
		return res;
	}

	function addSourceFrame(res, e) {
		var sourceFrame = findSourceFrame(e);
		origins[e.origin] = origins[e.origin] || {};
		if (sourceFrame && !origins[e.origin][sourceFrame]) {
			origins[e.origin][sourceFrame] = {
				seq          : generateSEQ(),
				hasHandshake : false,
				res_next     : Math.round((Math.random() + .1) * 10000),
				reqList      : {}
			};
		}
		return sourceFrame;
	}

	function sendPostMessage(msg, url, iframe) {
		msg = JSON.stringify(msg);
		if (iframe) {
			iframe.contentWindow.postMessage(msg, url);
		}
		_Dev.log(msg);
	}

	function onResponseReceived(res, e) {
		var req = res.ref_id && rListById[res.ref_id];
		if (req && !req.state) {
			if (req.action === "sync_ack" && res.action === "ack" && res.ack === (req.details.seq + 1)) {
				origins[e.origin][req.sourceFrame].hasHandshake = true;
			}
			else if (req.action === "vm" && res.action === "vmr" && paymentInProgress && paymentInProgress.sourceFrame === req.sourceFrame) {
				paymentInProgress.res_id = res.id;
				clearTimeout(req.timeout);
				req.state = "completed";
				if (res._intData) {
					performApplePayMerchantValidationPromise(res._intData.url, res._intData.valURL, res._intData.ssid, res._intData.flow)
						.then(function (merchantSession) {
							if (merchantSession) {
								try {
									_applePay.session.completeMerchantValidation(merchantSession);
								} catch (err) {
									_applePay.session.completePayment(ApplePaySession.STATUS_FAILURE);
									onValidateMerchantError();
									saveTrx("FAILURE");
								}
							} else {
								_applePay.session.completePayment(ApplePaySession.STATUS_FAILURE);
								onValidateMerchantError();
								saveTrx("FAILURE");
							}
						});
				} else {
					/** the old way */
					onValidateMerchantResponse(res, e);
				}
			}
			if (!req.state) {
				clearTimeout(req.timeout);
				req.state = "completed";
				req.callback && req.callback(res, e);
			}
		}
	}

	function getResID(origin, sourceFrame) {
		var obj = origins[origin] && origins[origin][sourceFrame] || {res_next: -1000};
		return obj.res_next++;
	}

	function onRequest(res, e) {
		var sourceFrame = addSourceFrame(res, e);
		if (!sourceFrame) {
			return
		}
		if (res.action === "sync") {
			var action = "sync_ack";
			var my_id = getResID(e.origin, sourceFrame);
			var data = {
				action          : action,
				supportApplePay : !!(window.ApplePaySession && ApplePaySession.canMakePayments()),
				maxSupportedVersion : maxSupportedVersion,
				ack             : res.seq + 1,
				seq             : seq,
				req_id          : res.id
			};
			rLog(e.origin, sourceFrame, my_id, action, data);
			sendPostMessage(data, e.origin, sourceFrame);
		}
		else if (res.action === "pay") {
			var request = validatePaymentRequest(res);
			if ((window.ApplePaySession && ApplePaySession.canMakePayments())
				&& sourceFrame
				&& origins[e.origin]
				&& origins[e.origin][sourceFrame]
				&& origins[e.origin][sourceFrame].hasHandshake
				&& request
				&& (!paymentInProgress || (paymentInProgress && paymentInProgress.sourceFrame === sourceFrame))
			) {
				clearPaymentInProgressTimeout();
				clearBeginSessionTimeout();
				paymentInProgress = {
					id          : res.id,
					res_id      : res.id,
					origin      : e.origin,
					sourceFrame : sourceFrame
				};
				beginApplePay_2(request, res.ver);
			} else {
				_sendError("CANCELLED");
			}
		}
		else if (res.action === "begin"
			&& paymentInProgress
			&& paymentInProgress.sourceFrame === sourceFrame
			&& paymentInProgress.origin === e.origin
			&& _applePay
			&& _applePay.session
			&& _applePay.delay
		) {
			doBegin(res.details);
		}
		else if (res.action === "canMakePaymentsWithActiveCard"
			&& res.details
			&& res.details.merchantIdentifier
		) {
			if ((window.ApplePaySession && ApplePaySession.canMakePayments())
				&& sourceFrame
				&& origins[e.origin]
				&& origins[e.origin][sourceFrame]
				&& origins[e.origin][sourceFrame].hasHandshake
				&& (!paymentInProgress || (paymentInProgress && paymentInProgress.sourceFrame === sourceFrame))
			) {
				window.ApplePaySession.canMakePaymentsWithActiveCard(res.details.merchantIdentifier)
					.then(function (appleResp) {
						_sendHasActiveCardResp(appleResp);
					})
					.catch(function (reason) {
						_sendHasActiveCardResp(true, true);
					});
			} else {
				_sendError("INVALID_REQUEST");
			}
		}
		else if (res.action === "completeTrx"
			&& paymentInProgress
			&& paymentInProgress.sourceFrame === sourceFrame
			&& paymentInProgress.origin === e.origin
		) {
			completeApplePayTrx(res);
		}

		function _sendHasActiveCardResp(appleResp, err) {
			var action = "has_active_card";
			var my_id = getResID(e.origin, sourceFrame);
			var data = {
				action: action,
				req_id: res.id,
				hasErrors: !!err,
				canMakePaymentsWithActiveCard: appleResp
			};
			rLog(e.origin, sourceFrame, my_id, action, data);
			sendPostMessage(data, e.origin, sourceFrame);
		}

		function _sendError(_action) {
			var _my_id = getResID(e.origin, sourceFrame);
			var _data = {
				req_id: res.id,
				res_id: res.id,
				msg: paymentInProgress ? "ERROR: paymentInProgress" : "Invalid request!",
				requested_action: res.action
			};
			rLog(e.origin, sourceFrame, _my_id, _action || "CANCELLED", _data);
			sendPostMessage(_data, e.origin, sourceFrame);
		}
	}

	function rLog(origin, sourceFrame, my_id, action, data) {
		if (origin && sourceFrame && my_id && action && data) {
			data.id = my_id;
			data.action = action;
			data.timestamp = Date.now();
			rListById[my_id] = {
				sourceFrame : sourceFrame,
				action      : action,
				type        : CONSTANTS.TYPE_RESPONSE,
				callback    : ((req_actions[action] && req_actions[action].callback) || function (a) {
					return a;
				}),
				details     : data
			};
			if (action === "INVALID_REQUEST") {
				rListById[my_id].state = "completed";
			}
			origins[origin][sourceFrame].reqList[my_id] = rListById[my_id];
			if (req_actions[action] && req_actions[action].timeout) {
				rListById[my_id].timeout = setTimeout(function () {
					if (rListById[my_id]) {
						onResponseTimeout(my_id);
					}
				}, req_actions[action].timeout);
			}
		}
	}

	function onResponseTimeout(req_id) {
		var req = rListById[req_id];
		if (req && !req.state) {
			req.state = "timeout";
		}
	}

	function validatePaymentRequest(data) {
		var isValid = false;
		if (data && data.details) {
			isValid = true;
			var request = data.details;
			["countryCode", "currencyCode", "supportedNetworks", "merchantCapabilities", "total"].forEach(
				function (v, i) {
					isValid = isValid && !!request[v];
				});
			isValid = isValid && request.total.hasOwnProperty("label");
			isValid = isValid && request.total.hasOwnProperty("amount");
			return isValid && request;
		}
	}

	function saveTrx(status) {
		if (typeof paymentInProgress === 'object') {
			clearPaymentInProgressTimeout();
			paymentInProgress.status = status;
			paymentsList.push(paymentInProgress);
			paymentInProgress = false;
		}
	}

	function onApCancel(data) {
		var origin = paymentInProgress.origin;
		var sourceFrame = paymentInProgress.sourceFrame;
		var my_id = getResID(origin, sourceFrame);
		data.req_id = paymentInProgress.res_id;
		rLog(origin, sourceFrame, my_id, "CANCELLED", data);
		sendPostMessage(data, origin, sourceFrame);
		saveTrx("CANCELLED");
	}

	function onValidateMerchant(data) {
		var origin = paymentInProgress.origin;
		var sourceFrame = paymentInProgress.sourceFrame;
		var my_id = getResID(origin, sourceFrame);
		paymentInProgress.VM_id = my_id;
		data.req_id = paymentInProgress.res_id;
		rLog(origin, sourceFrame, my_id, "vm", data);
		sendPostMessage(data, origin, sourceFrame);
	}

	function onValidateMerchantError() {
		if (paymentInProgress) {
			var origin = paymentInProgress.origin;
			var sourceFrame = paymentInProgress.sourceFrame;
			var my_id = getResID(origin, sourceFrame);
			paymentInProgress.VM_id = my_id;
			var _data = {
				req_id: paymentInProgress.res_id,
				res_id: paymentInProgress.res_id,
				msg: "ERROR: Validation failed!"
			};
			rLog(origin, sourceFrame, my_id, "CANCELLED", _data);
			sendPostMessage(_data, origin, sourceFrame);
		}
	}

	function onValidateMerchantResponse(data, e) {
		if (data && e && paymentInProgress && e.origin === paymentInProgress.origin) {
			if (data.status === "SUCCESS" && data.mso) {
				try {
					var merchantSession = JSON.parse(data.mso);
					_applePay.session.completeMerchantValidation(merchantSession);
				} catch (e) {
					_applePay.session.completePayment(ApplePaySession.STATUS_FAILURE);
					saveTrx("FAILURE");
				}
			} else {
				_applePay.session.completePayment(ApplePaySession.STATUS_FAILURE);
				saveTrx("FAILURE");
			}
		} else {
			completeApplePayTrx();
		}
	}

	function onPaymentAuthorized(data) {
		var origin = paymentInProgress.origin;
		var sourceFrame = paymentInProgress.sourceFrame;
		var my_id = getResID(origin, sourceFrame);
		paymentInProgress.pma_id = my_id;
		data.req_id = paymentInProgress.res_id;
		rLog(origin, sourceFrame, my_id, "paut", data);
		sendPostMessage(data, origin, sourceFrame);
	}

	function onMessage(e) {
		var data = getFormattedResponse(e);
		if (data && data.id) {
			var req = data.ref_id && rListById[data.ref_id];
			if (req) { // response
				onResponseReceived(data, e);
			}
			else { // new Request
				onRequest(data, e);
			}
		}
	}

	if (window.addEventListener) {
		window.addEventListener("message", onMessage, false);
	} else {
		window.attachEvent("onmessage", onMessage);
	}

	function beginApplePay_2(request, ver) {
		var version = Math.min(maxSupportedVersion, parseInt(ver || 1));
		if (request) {
			_applePay = {
				paymentAuthorizationStarted : false,
				paymentCanceled             : false,
				delay                       : false,
				beginSessionTimeout         : false,
				session                     : new ApplePaySession(version, request)
			};
			_applePay.session.onvalidatemerchant = function (event) {
				onValidateMerchant({validationURL : event.validationURL});
			};
			_applePay.session.oncancel = function () {
				_applePay.paymentCanceled = true;
				onApCancel({msg : "Cancelled by user!"});
			};
			_applePay.session.onpaymentauthorized = function (event) {
				_applePay.paymentAuthorizationStarted = true;
				onPaymentAuthorized({applePayJson : JSON.stringify(event.payment)});
			};

			if (!request.delay){
				_applePay.session.begin();
			} else {
				paymentInProgress.timeout = setTimeout(function () {
					if(_applePay.delay) {
						clearBeginSessionTimeout();
						_applePay.session = false;
						_applePay.delay = false;
						saveTrx("TIMEOUT_30s");
					}
				}, maxDelayTime);

				function _begin() {
					if (_applePay.beginSessionTimeout && _applePay.delay) {
						clearTimeout(_applePay.beginSessionTimeout);
						_applePay.beginSessionTimeout = setTimeout(_begin, 2000);
					} else if (_applePay.session) {
						_applePay.session.begin();
					}
				}
				_applePay.delay = true;
				_applePay.beginSessionTimeout = setTimeout( _begin, 2000);
			}
		}
	}

	function doBegin(request) {
		if (_applePay && _applePay.session && _applePay.delay) {
			clearPaymentInProgressTimeout();
			_applePay.delay = false;
		}
	}

	function clearBeginSessionTimeout() {
		if (typeof _applePay === 'object' && _applePay.beginSessionTimeout) {
			clearTimeout(_applePay.beginSessionTimeout);
			_applePay.beginSessionTimeout = false;
		}
	}
	function clearPaymentInProgressTimeout() {
		if (typeof paymentInProgress === 'object' && paymentInProgress.timeout) {
			clearTimeout(paymentInProgress.timeout);
			delete paymentInProgress.timeout;
		}
	}

	function completeApplePayTrx(retData) {
		if (!paymentInProgress) {
			return
		}
		var result = {};
		if (typeof retData === 'object') {
			result = retData;
		}
		if (result.trxStatus && result.trxStatus === "APPROVED") {
			if (!_applePay.paymentCanceled) {
				try {
					_applePay.session.completePayment(ApplePaySession.STATUS_SUCCESS);
					saveTrx("SUCCESS");
				} catch (e) {
					saveTrx("UNKNOWN");
				}
			}
		}
		else if (!_applePay.paymentCanceled) {
			try {
				_applePay.session.completePayment(ApplePaySession.STATUS_FAILURE);
			} catch (e) {
			}
			saveTrx("FAILURE");
		}
	}
	function performApplePayMerchantValidationPromise (url, valURL, ssid, flow) {
		return new Promise(function (resolve, reject) {
			var xhr = new XMLHttpRequest();
			xhr.onload = function () {
				try {
					var data = JSON.parse(this.responseText);
					resolve(data.appleResponse);
				} catch (e) {
					if (window._apple_session) {
						window._apple_session.completePayment(ApplePaySession.STATUS_FAILURE);
					} else {
						reject();
					}
				}
			};
			xhr.onerror = reject;
			xhr.timeout = 20000;
			xhr.ontimeout = reject;

			var searchStr = '?validationUrl=' + valURL + '&ssid=' + ssid;
			searchStr += flow ? ('&mobilePayFlow=' + flow) : '';
			searchStr += ('&ts=' + (new Date()).getTime());
			xhr.open('GET', url + searchStr);
			xhr.send();
		});
	}

	return {
		ver : function () {
			return '1.0.5';
		},
		canMakePayments : function () {
			return !!(window.ApplePaySession && ApplePaySession.canMakePayments());
		},
		getMaxSupportedApiVersion : function () {
			return maxSupportedVersion;
		},
		showPaymentList : function () {
			return JSON.stringify(paymentsList);
		}
	}
})();